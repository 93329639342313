.backup-page {
    display: flex;
    justify-content: space-between;
    /* padding: 20px; */
  }
  
  .backup-left {
    width: 50%;
    padding-left: 40px;
  }
  
  .backup-right {
    width: 50%;
    /* padding: 20px; */
    padding-left: 40px;
    border-left: 1px solid #ccc;
  }
  
  .backup-right h2,
  .backup-right h3 {
    margin: 10px 0;
  }
  
  .backup-right label {
    display: block;
    margin-bottom: 10px;
  }
  